@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.ortto {
	form {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
	}

	&.two-thirds,
	&.half {
		form {
			flex-direction: row;
		}

		.ap3w-form-input:not(.ap3w-input-bool) {
			flex-basis: 100%;
		}

		@include mq('lg') {
			.ap3w-form-input:not(.ap3w-input-bool) {
				flex-basis: calc(50% - var(--spacing--md) / 2);
			}
		}
	}

	.ap3w-form-input {
		position: relative;
		color: currentColor;
		padding-top: 2rem;
		padding-right: var(--spacing--md);

		&:not(.ap3w-input-bool) {
			margin-bottom: 2.4rem;
			width: 100%;
		}

		&.hasError {
			color: var(--color-error);
		}

		&.isDisabled {
			color: var(--color-gradient--5);
		}
	}

	.ap3w-form-input-label,
	.ap3w-form-input-phone-label {
		@include formfield-label;
		line-height: var(--line-height--2xs);
		position: absolute;
		top: 50%;
		transition: font-size 0.3s ease, top 0.3s ease;
	}

	.ap3w-form-input.focused .ap3w-form-input-label,
	.ap3w-form-input.focused .ap3w-form-input-phone-label {
		top: 0;
		font-size: var(--font-size--3xs);
	}

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="tel"] {
		// can't use mixin because of the type selector and we don't have specific classes for input
		@include paragraph;
		outline: none;
		background-color: inherit;

		-webkit-appearance: none;

		&:focus {
			border-color: currentColor;
		}

		margin: var(--spacing--2xs) 0;
		width: 100%;
		max-height: 4rem;
		border: 0;
		display: flex;
		border-bottom: var(--border--sm) solid currentColor;
		appearance: none;
		-webkit-appearance: none;
		position: relative;
		z-index: 1;

		&::placeholder {
			color: currentColor;
		}

		.isDisabled & {
			cursor: not-allowed;
		}
	}

	.ap3w-checkbox {
		position: relative;
		color: currentColor;
		margin: var(--spacing--md) 0;

		.ap3w-checkbox__inner {
			display: flex;
			align-items: flex-start;

			input {
				position: relative;
				width: 2.4rem;
				height: 2.4rem;
				appearance: none;
				margin-right: var(--spacing--xs);

				&:before {
					content: "";
					display: block;
					width: 2.4rem;
					height: 2.4rem;
					border: var(--border--sm) solid currentColor;
					border-radius: 0;
					margin-right: var(--spacing--md);
					flex-shrink: 0;

					.hasError & {
						border-color: var(--color-error);
					}
				}

				&:after {
					content: "";
					display: block;
					width: calc(2.4rem / 4);
					height: calc(2.4rem / 2);
					transform: rotate(45deg) translate(-50%, 50%);
					border: solid currentColor;
					opacity: 0;
					border-width: 0 var(--border--sm) var(--border--sm) 0;
					position: absolute;
					left: 65%;
					top: 0.2rem;
				}

				&:checked {
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}

	.ap3w-checkbox__text {
		p {
			@include body--md-reg;

			&:last-child {
				font-size: var(--font-size--3xs);
				color: currentColor;
				filter: brightness(0.9);
			}
		}
	}

	.ap3w-input-bool {
		position: relative;
		display: flex;
		flex-flow: column;
		color: currentColor;
		width: 100%;

		input {
			width: 2.4rem;
			height: 2.4rem;
			margin: var(--spacing--2xs) 0;
			display: none;
			opacity: 0;
			appearance: none;

			&:checked+.ap3w-form-checkbox__text:after {
				border-color: inherit;
				top: 1rem;
			}
		}

		.ap3w-form-checkbox__text {
			display: flex;
			cursor: pointer;
			position: relative;
			@include paragraph;

			&:before {
				content: "";
				display: block;
				width: 2.4rem;
				height: 2.4rem;
				border: var(--border--sm) solid currentColor;
				border-radius: 0;
				margin-right: var(--spacing--sm);
				flex-shrink: 0;

				.hasError & {
					border-color: var(--color-error);
				}
			}

			&:after {
				content: "";
				display: block;
				width: calc(2.4rem / 3.2);
				height: calc(2.4rem / 2);
				transform: rotate(45deg) translate(-50%, -50%);
				border: solid transparent;
				border-width: 0 var(--border--md) var(--border--md) 0;
				position: absolute;
				left: 0.6rem;
				top: 50%;
			}
		}
	}

	.ap3w-form-button {
		width: 100%;

		button {
			@include button;
			margin-top: var(--spacing--lg);
			color: currentColor;
			border-color: currentColor;
			background: transparent;
		}
	}

	input:focus~.ap3w-form-input-label,
	.ap3w-form-input.hasInput .ap3w-form-input-label {
		transform: translateY(-2rem);
		font-weight: var(--font-weight--bold);
	}

	.ap3w-form-input[data-input-type="check"]+.ap3w-text {
		margin-top: var(--spacing--xs);

		p {
			@include paragraph;
			font-size: var(--font-size--3xs);
		}
	}

	.ap3w-form-input[data-input-type="multi_select"] {
		display: flex;
		flex-direction: column;
		width: 100%;

		label {
			margin-bottom: var(--spacing--xs);
		}
	}

	.ap3w-form-input[data-input-type="multi_select"]+.ap3w-text,
	.ap3w-form-input[data-input-type="tel"]+.ap3w-text {
		font-size: var(--font-size--lg);
		margin: var(--spacing--lg) 0 var(--spacing--sm) 0;
		width: 100%;
	}

	.ap3w-form-input-select-unstyled {
		background-color: inherit;

		option {
			font-size: var(--font-size--2xs);
		}

		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			border-left: 1px solid currentColor;
		}

		&::-webkit-scrollbar-thumb {
			background: currentColor;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: inherit;
		}
	}

	.select-all-options-button {
		background-color: transparent;
		border: var(--border--sm) solid currentColor;
		margin-top: var(--spacing--xs);
	}
}

.ortto-noImage {
	.ap3w-form-input {
		width: 100%;
		flex-basis: 100%;

		@include mq('md') {
			flex-basis: 50%;
		}

		@include mq('lg') {
			flex-basis: 25%;
		}
	}

	.ap3w-form-input___fullWidth {
		width: 100%;
		flex-basis: 100%;
	}
	
	.ap3w-form-input___fullWidth ~ .ap3w-form-button {
		margin: 0;
		padding-right: var(--spacing--md);
	  }

	.ap3w-checkbox {
		width: 100%;
		flex-basis: 100%;

		@include mq('md') {
			flex-basis: 50%;
		}
	}

	.ap3w-form-button {
		position: relative;

		@include mq('md') {
			position: absolute;
			right: 0;
			bottom: 0;
			width: fit-content;
			margin-right: var(--spacing--md);
		}
	}
}



.ortto_displayButtonRight {

	.ap3w-checkbox,
	.ap3w-text {
		width: 100%;
		flex-basis: 100%;

		@include mq('md') {
			flex-basis: 50%;
			padding-right: var(--spacing--md);
		}
	}

	.ap3w-form-button {
		position: relative;

		@include mq('md') {
			position: absolute;
			right: 0;
			bottom: 0;
			width: fit-content;
			margin-right: calc(var(--spacing--md) * 2);
		}
	}
}

.ortto_eventDisplayButtonRight {
	.ap3w-form-button {
		position: relative;

		@include mq('lg') {
			position: absolute;
			bottom: 0;
			left: 100%;
			width: max-content;
			padding-left: var(--spacing--3xl);
		}
	}
}