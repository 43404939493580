@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.Link {
	@include action;
	@include paragraph;
	color: var(--color--primary);
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	&___noUnderline {
		&:hover {
			text-decoration: none;
		}
	}

	&___underline {
		text-decoration: underline;
	}

	&__sm {
		@include paragraph;
	}

	&___md {
		@include heading--xl;
	}

	&___lg {
		@include text-link--lg;
	}

	&___xl {
		@include heading--6xl;
	}

	&___icon {
		display: flex;
		align-items: center;
		column-gap: 1rem;
	}

	&___violet {
		color: var(--color-violet-light);

		.Link_iconContainer {
			fill: var(--color-violet-light);
		}
	}

	&___inheritsColor {
		color: currentColor;
	}
}

.Link_iconContainer {
	display: inline-block;
	margin-top: -0.3rem;
}
