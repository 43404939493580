/* ------------------------------------ *\
		elements.page
\* ------------------------------------ */

/**
 *  Elements – unclassed HTML elements,
 *  redefine browsers' default styling
*/

html {
	font-family: var(--font-primary);
	font-size: 10px;
}

body {
	font-size: var(--font-size--xs);
	line-height: var(--line-height--2xl);
	background-color: currentColor;
}
