/* Fonts downloaded from Google Web Fonts 
   https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap
*/

/* vietnamese */
@font-face {
	font-family: "Space Grotesk";
	font-style: normal;
	font-weight: 300 700;
	font-display: swap;
	src: url(/fonts/spacegrotesk/V8mDoQDjQSkFtoMM3T6r8E7mPb54C-s0.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
		U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-family: "Space Grotesk";
	font-style: normal;
	font-weight: 300 700;
	font-display: swap;
	src: url(/fonts/spacegrotesk/V8mDoQDjQSkFtoMM3T6r8E7mPb94C-s0.woff2) format("woff2");
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: "Space Grotesk";
	font-style: normal;
	font-weight: 300 700;
	font-display: swap;
	src: url(/fonts/spacegrotesk/V8mDoQDjQSkFtoMM3T6r8E7mPbF4Cw.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122,
		U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 100;
	src: url("/fonts/HelveticaNeueUltraLight.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: italic;
	font-weight: 100;
	src: url("/fonts/HelveticaNeueUltraLightItalic.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 300;
	src: url("/fonts/HelveticaNeueLight.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: italic;
	font-weight: 300;
	src: url("/fonts/HelveticaNeueLightItalic.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 500;
	src: url("/fonts/HelveticaNeueMedium.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: italic;
	font-weight: 500;
	src: url("/fonts/HelveticaNeueMediumItalic.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 700;
	src: url("/fonts/HelveticaNeueBold.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: italic;
	font-weight: 700;
	src: url("/fonts/HelveticaNeueBoldItalic.woff") format("woff");
}

@font-face {
	font-family: "Helvetica Neue";
	font-style: normal;
	font-weight: 900;
	src: url("/fonts/HelveticaNeueHeavy.woff") format("woff");
}
@font-face {
	font-family: "Helvetica Neue";
	font-style: italic;
	font-weight: 900;
	src: url("/fonts/HelveticaNeueHeavyItalic.woff") format("woff");
}
