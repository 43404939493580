/* ------------------------------------ *\
		settings.colors
\* ------------------------------------ */

:root {
	// Base colors
	--color-black: #222222;
	--color-white: #ffffff;

	// Variations of gray named with the alpha percentage
	--color-gradient--5: #{darken(#f2f2f2, 5%)};
	--color-gradient--8: #{darken(#f2f2f2, 8%)};
	--color-gradient--10: #{darken(#f2f2f2, 10%)};
	--color-gradient--20: #{darken(#f2f2f2, 20%)};
	--color-gradient--30: #{darken(#f2f2f2, 30%)};
	--color-gradient--40: #{darken(#f2f2f2, 40%)};
	--color-gradient--50: #{darken(#f2f2f2, 50%)};
	--color-gradient--60: #{darken(#f2f2f2, 60%)};
	--color-gradient--70: #{darken(#f2f2f2, 70%)};
	--color-gradient--80: #{darken(#f2f2f2, 80%)};
	--color-gradient--90: #{darken(#f2f2f2, 90%)};
	--color-gradient--100: #{darken(#f2f2f2, 100%)};

	// Theme colors
	--color-primary: var(--color-blue);
	--color-secondary: var(--color-violet);
	--color-tertiary: var(--color-red);

	// Blue theme colors
	--color-blue: #0020be;
	--color-blue-light: #99ccff;
	--color-blue-lightest: #dcedf5;
	
	// Violet theme colors
	--color-violet: #201b59;
	--color-violet-light: #D0BBE6;
	--color-violet-lightest: #ECEBFF;

	// Green theme colors
	--color-green: #04393B;
	--color-green-light: #8ECFD2;
	--color-green-lightest: #DCF0F0;

	// Grey theme colors
	--color-grey: #3C464C;
	--color-grey-light: #C8D0DA;
	--color-grey-lightest: #E9ECEE;

	// Red theme colors
	--color-red: #593244;
	--color-red-light: #E64652;
	--color-red-lightest: #FFE6E6;

	--color-sand-lightest: #EBEBEB;

	// Utilities colors
	--color-error: #c80000;
	--color-dark: #2c2c2c;
	--color-light: var(--color-violet-light);
	--color-white-grey: #F9F9F9;

	// Border colors
	--color-border--gray: var(--color-gradient--20);
	--color-border--light: var(--color-light);

	// Shadows
	--shadow-color--milder: rgba(#2c2c2c, 0.1);
	--shadow-color--mild: rgba(#2c2c2c, 0.2);
	--shadow-color--base: rgba(#2c2c2c, 0.5);
}

// Theme: Kogp
[data-theme='kogp'] {
	--color-primary: var(--color-blue);
	--color-secondary: var(--color-cyan);
	--color-tertiary: var(--color-red-light);
	
	// Blue theme colors
	--color-blue: #173448; //nile
	--color-blue-light: #00607a; //blue
	--color-blue-lightest: #739da3; //weldonBlue
	--color-cyan: #37c3cc; //cyan

	// Violet theme colors (not in use, so therefore set to cyan)
	--color-violet: var(--color-cyan);
	--color-violet-light: var(--color-cyan);
	--color-violet-lightest: var(--color-cyan);

	// Green theme colors
	--color-green: #82dab0; //algae
	--color-green-light: var(--color-green);
	--color-green-lightest: var(--color-green);

	// Grey theme colors
	--color-grey-light: #767676; //porcelain
	--color-grey-lightest: #f0f5f5; //porcelain

	// Red theme colors
	--color-red: #e85c54; //fireOpal
	--color-red-light: #e698bc; //kobi
	--color-red-lightest: var(color-red-light);

	// Utilities colors
	--color-white-grey: var(--color-grey-lightest);
	--color-light: var(--color-white);
}