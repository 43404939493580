.Preview_top {
	padding: 0.6rem 1rem;
	background-color: lemonchiffon;
	text-align: center;
	color: black;
	position: sticky;
	top: 0;
	z-index: 9999;
}

.Preview_link {
	padding: 0;
	background: transparent;
	border-color: transparent;
	font-weight: bold;
	cursor: pointer;
	color: darkslateblue;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}
