/* ------------------------------------ *\
		settings.typography
\* ------------------------------------ */

:root {
	// Font family
	--font-primary: "Space Grotesk", sans-serif;

	// Font weights
	--font-weight--light: 300;
	--font-weight--normal: 400;
	--font-weight--semibold: 500;
	--font-weight--bold: 600;
	--font-weight--black: 650;
	--font-weight--extrablack: 700;

	// Font sizes
	--font-size--3xs: 1.2rem;
	--font-size--2xs: 1.4rem;
	--font-size--xs: 1.6rem;
	--font-size--sm: 1.8rem;
	--font-size--md: 1.9rem;
	--font-size--lg: 2rem;
	--font-size--xl: 2.8rem;
	--font-size--2xl: 3.5rem;
	--font-size--3xl: 3.8rem;
	--font-size--4xl: 4.5rem;
	--font-size--5xl: 6rem;
	--font-size--6xl: 7.4rem;

	// Line heights
	--line-height--2xs: 1;
	--line-height--xs: 1.06;
	--line-height--sm: 1.14;
	--line-height--md: 1.17;
	--line-height--lg: 1.25;
	--line-height--xl: 1.38;
	--line-height--2xl: 1.5;
	--line-height--3xl: 2;
	--line-height--4xl: 3;

	// Letter spacing
	--letter-spacing-heading: -0.04em;
	--letter-spacing-body: -0.03em;
}

// Theme: Kogp
[data-theme='kogp'] {
	&:root {
		// Font family
		--font-primary: "Helvetica Neue", sans-serif;
	
		// Font weights
		--font-weight--light: 300; // 100 is available, but not used in the theme.
		--font-weight--normal: 300;
	
		// Letter spacing
		--letter-spacing-heading: 0;
		--letter-spacing-body: 0.03em;
	}
}