/* ------------------------------------ *\
		settings.spacing
\* ------------------------------------ */

:root {
	--spacing--base: 2.5rem;

	--spacing--2xs: calc(var(--spacing--base) / 5); // 0.5rem
	--spacing--xs: calc(var(--spacing--base) / 3.33); // 0.75rem
	--spacing--sm: calc(var(--spacing--base) * 0.75); // 1.875rem
	--spacing--md: calc(var(--spacing--base) * 1.25); // 3.125rem
	--spacing--lg: calc(var(--spacing--base) * 1.5); // 3.75rem
	--spacing--xl: calc(var(--spacing--base) * 1.8); // 4.5rem
	--spacing--2xl: calc(var(--spacing--base) * 2); // 5rem
	--spacing--3xl: calc(var(--spacing--base) * 3); // 7.5rem
	--spacing--4xl: calc(var(--spacing--base) * 4); // 10rem
	--spacing--5xl: calc(var(--spacing--base) * 5); // 12.5rem
	--spacing--6xl: calc(var(--spacing--base) * 6); // 15rem
	--spacing--8xl: calc(var(--spacing--base) * 8); // 20rem
	--spacing--10xl: calc(var(--spacing--base) * 10); // 25rem
	--spacing--16xl: calc(var(--spacing--base) * 16); // 40rem
}
