@use "sass:math";
/* ------------------------------------ *\
		settings.grid-variables
\* ------------------------------------ */

:root {
	--grid-gutter: 1rem;
	--grid-gutter--lg: 4.5rem;
}

// Grid based on BlazeUI's grid
// More info: http://blazeui.com/objects/grid/
$grid-col-widths : (
	"0col": 0,
	"1col": math.div(1, 24),
    "2col": math.div(2, 24),
    "3col": math.div(3, 24),
    "4col": math.div(4, 24),
    "5col": math.div(5, 24),
    "6col": math.div(6, 24),
    "7col": math.div(7, 24),
    "8col": math.div(8, 24),
    "9col": math.div(9, 24),
    "10col": math.div(10, 24),
    "11col": math.div(11, 24),
    "12col": math.div(12, 24),
    "13col": math.div(13, 24),
    "14col": math.div(14, 24),
    "15col": math.div(15, 24),
    "16col": math.div(16, 24),
    "17col": math.div(17, 24),
    "18col": math.div(18, 24),
    "19col": math.div(19, 24),
    "20col": math.div(20, 24),
    "21col": math.div(21, 24),
    "22col": math.div(22, 24),
    "23col": math.div(23, 24),
    "24col": 1,
);

$grid-widths: (
	0: 0,
	1: math.div(1, 100),
	2: math.div(2, 100),
	3: math.div(3, 100),
	4: math.div(4, 100),
	5: math.div(5, 100),
	6: math.div(6, 100),
	7: math.div(7, 100),
	8: math.div(8, 100),
	9: math.div(9, 100),
	10: math.div(1, 10),
	11: math.div(11, 100),
	12: math.div(12, 100),
	13: math.div(13, 100),
	14: math.div(14, 100),
	15: math.div(15, 100),
	16: math.div(1, 6),
	17: math.div(17, 100),
	18: math.div(18, 100),
	19: math.div(19, 100),
	20: math.div(1, 5),
	21: math.div(21, 100),
	22: math.div(22, 100),
	23: math.div(23, 100),
	24: math.div(24, 100),
	25: math.div(1, 4),
	26: math.div(26, 100),
	27: math.div(27, 100),
	28: math.div(28, 100),
	29: math.div(29, 100),
	30: math.div(3, 10),
	31: math.div(31, 100),
	32: math.div(32, 100),
	33: math.div(1, 3),
	34: math.div(34, 100),
	35: math.div(35, 100),
	36: math.div(36, 100),
	37: math.div(37, 100),
	38: math.div(38, 100),
	39: math.div(39, 100),
	40: math.div(2, 5),
	41: math.div(41, 100),
	42: math.div(42, 100),
	43: math.div(43, 100),
	44: math.div(44, 100),
	45: math.div(45, 100),
	46: math.div(46, 100),
	47: math.div(47, 100),
	48: math.div(48, 100),
	49: math.div(49, 100),
	50: math.div(1, 2),
	51: math.div(51, 100),
	52: math.div(52, 100),
	53: math.div(53, 100),
	54: math.div(54, 100),
	55: math.div(55, 100),
	56: math.div(56, 100),
	57: math.div(57, 100),
	58: math.div(58, 100),
	59: math.div(59, 100),
	60: math.div(3, 5),
	61: math.div(61, 100),
	62: math.div(62, 100),
	63: math.div(63, 100),
	64: math.div(64, 100),
	65: math.div(65, 100),
	66: math.div(2, 3),
	67: math.div(67, 100),
	68: math.div(68, 100),
	69: math.div(69, 100),
	70: math.div(7, 10),
	71: math.div(71, 100),
	72: math.div(72, 100),
	73: math.div(73, 100),
	74: math.div(74, 100),
	75: math.div(3, 4),
	76: math.div(76, 100),
	77: math.div(77, 100),
	78: math.div(78, 100),
	79: math.div(79, 100),
	80: math.div(4, 5),
	81: math.div(81, 100),
	82: math.div(82, 100),
	83: math.div(83, 100),
	84: math.div(84, 100),
	85: math.div(85, 100),
	86: math.div(86, 100),
	87: math.div(87, 100),
	88: math.div(88, 100),
	89: math.div(89, 100),
	90: math.div(9, 10),
	91: math.div(91, 100),
	92: math.div(92, 100),
	93: math.div(93, 100),
	94: math.div(94, 100),
	95: math.div(95, 100),
	96: math.div(96, 100),
	97: math.div(97, 100),
	98: math.div(98, 100),
	99: math.div(99, 100),
	100: 1,

) !default;

$grid-widths: map-merge($grid-widths, $grid-col-widths);