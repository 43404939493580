.Icon {
	display: flex;
	justify-content: center;
	align-items: center;

	&___sm {
		width: var(--icon-size--sm);
		height: var(--icon-size--sm);
	}

	&___md {
		width: var(--icon-size--md);
		height: var(--icon-size--md);
	}

	&___lg {
		width: var(--icon-size--lg);
		height: var(--icon-size--lg);
	}

	&___xl {
		width: var(--icon-size--xl);
		height: var(--icon-size--xl);
	}

	&__auto {
		width: auto;
		height: auto;
	}

	&___circle {
		border: var(--border--sm) solid var(--color-border--gray);
		border-radius: 100%;
	}

	svg {
		display: block;
		width: 100%;
		height: 100%;
		fill: currentColor;
	}
}
