.split-text {
	display: block;
	position: relative;
	opacity: 1;	
	animation: revealHeading 2s cubic-bezier(.23, 1, .32, 1) forwards;		
	transform-style: preserve-3d;
	transform-origin: left;
	clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);   
	transform: translate(0%, 20%); 
	$animation-delay-step: 0.1s;
	$animation-delay-count: 10; // Number of elements
	@for $i from 1 through $animation-delay-count {
		&:nth-child(#{$i}) {
			 animation-delay: #{$i * $animation-delay-step};
		}
	}
	@keyframes revealHeading {
		0% {
			opacity: 1;
			transform-style: preserve-3d;
			transform-origin: left;
			clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
			transform: translate(0%, 20%);
		}  
		100% {
			opacity: 1;
			transform-style: preserve-3d;
			transform-origin: left;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			transform: translate(0%, 0%);
		}
	}
}